import { Table } from "components/Table"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useEventCallback,
} from "@mui/material"
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { makeStyles } from "@mui/styles"
import { differenceInWeeks } from "date-fns"
import { ikbtStatus } from "utils/ikbtStatus"
import { PatientStatus } from "./PatientStatus"

const useStyles = makeStyles((theme) => ({
  accordionTitle: {
    color: theme.palette.white,
    textDecoration: "none",
    fontWeight: "400",
  },
  accordion: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderLeft: "0px",
    borderRight: "0px",
  },
  tableLabelCentered: {
    display: "block",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
  accordionDetails: {
    backgroundColor: theme.palette.lighter_gray,
    color: theme.palette.white,
  },
  icon: {
    color: theme.palette.white,
  },
  actionButton: {
    textTransform: "none",
    padding: theme.spacing(1, 4),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderRadius: theme.spacing(4),
    fontSize: "1.2rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 1),
      borderRadius: theme.spacing(2),
      fontSize: "1rem",
    },
  },
  tableCell: {
    padding: `${theme.spacing(1, 0)} !important`,
  },
  infoText: {
    fontSize: "0.835rem",
    fontWeight: 500,
    margin: `${theme.spacing(0.5)} auto ${theme.spacing(0.8)}`,
    padding: theme.spacing(0.5, 1.3),
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: theme.spacing(1),
    color: theme.palette.black,
  },
}))

const requiresFeedback = (patient) =>
  [ikbtStatus.Active, ikbtStatus.Inactive].includes(patient.patientStatus) &&
  !patient.sessionFeedback

const requiresJournal = (patient) =>
  [ikbtStatus.Active, ikbtStatus.Inactive].includes(patient.patientStatus) &&
  patient.nonJournalizedSessionIds?.length > 0 &&
  patient.sessionFeedback

const requiresMotivation = (patient) => {
  const { motivate, patientStatus, lastModifiedDate } = patient
  const isPatientStatusInactive = patientStatus === ikbtStatus.Inactive
  const isPatientStatusInvited = patientStatus === ikbtStatus.Invited

  // Return false if already motivated or status is neither Inactive nor Invited
  if (motivate || (!isPatientStatusInactive && !isPatientStatusInvited)) {
    return false
  }

  // Always motivate inactive patients
  if (isPatientStatusInactive) {
    return true
  }

  // Motivate invited patients only if invited more than or equal to 1 week ago
  if (isPatientStatusInvited) {
    const minWeeksForMotivationAfterInvitation = 1
    const weeksSinceLastModification = differenceInWeeks(
      new Date(),
      new Date(lastModifiedDate)
    )
    return weeksSinceLastModification >= minWeeksForMotivationAfterInvitation
  }
}

const canDisconnect = (patient) => {
  const isInactive = patient.patientStatus === ikbtStatus.Inactive

  if (!isInactive) {
    return false
  }

  const weeksFromLastLogin = differenceInWeeks(
    new Date(),
    new Date(patient.lastLogin)
  )

  const hasCompletedProgram =
    patient.latestSessionModuleNumber === patient.programModulesCount
  const isMotivated = patient.motivate

  const inactiveOneWeekWithCompletedProgram =
    weeksFromLastLogin >= 1 && hasCompletedProgram

  const inactiveTwoWeeksAndMotivatedWithoutCompletingProgram =
    weeksFromLastLogin >= 2 && isMotivated && !hasCompletedProgram

  return (
    inactiveOneWeekWithCompletedProgram ||
    inactiveTwoWeeksAndMotivatedWithoutCompletingProgram
  )
}

const requiresAction = (patient) =>
  requiresFeedback(patient) ||
  requiresJournal(patient) ||
  requiresMotivation(patient) ||
  canDisconnect(patient)

const PatientTableTitle = ({ title, count = 0 }) => {
  const classes = useStyles()

  return (
    <Stack direction="row" spacing={2}>
      <Typography
        variant="body1"
        className={classes.accordionTitle}
        textTransform="uppercase"
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={classes.accordionTitle}
        textTransform="uppercase"
      >
        {count} ST
      </Typography>
    </Stack>
  )
}

const formatPatientName = ({
  rowData: {
    patientName,
    patientId,
    latestSessionModuleNumber,
    startedModuleNumber,
    programModulesCount,
  },
}) => (
  <>
    <Typography variant="body2">
      {patientName} | ID {patientId?.slice(0, 5)}
    </Typography>
    <Typography variant="body2">
      Modul{" "}
      {programModulesCount
        ? `${latestSessionModuleNumber} / ${programModulesCount}`
        : latestSessionModuleNumber}
      {startedModuleNumber && startedModuleNumber !== latestSessionModuleNumber
        ? ` (startat ${startedModuleNumber})`
        : ""}
    </Typography>
  </>
)

const formatStatus = ({ rowData }) => <PatientStatus patient={rowData} />

/**
 * Renders a table with IKBT patients and their status.
 *
 * @template T
 * @param {Array<T>} patients
 * @param {(patient: T, value: boolean) => void} onFeedbackChange
 * @param {(patient: T, value: boolean) => void} onMotivate
 * @param {(patient: T) => void} onSignJournal
 *
 * @return {JSX.Element}
 */
export const PatientStatusTable = ({
  patients = [],
  onFeedbackChange,
  onSignJournal,
  onMotivate,
}) => {
  const classes = useStyles()
  const defaultPanel = useRef()
  const [openedPanel, setOpenedPanel] = useState()

  const onFeedbackChangeEvent = useEventCallback(onFeedbackChange)
  const onSignJournalEvent = useEventCallback(onSignJournal)
  const onMotivateEvent = useEventCallback(onMotivate)

  const columns = useMemo(
    () => [
      {
        id: "patientName",
        sortable: false,
        label: "Patient",
        mobileTitle: true,
        formatBodyCell: formatPatientName,
      },
      {
        id: "status",
        sortable: false,
        label: <span className={classes.tableLabelCentered}>Status</span>,
        formatBodyCell: formatStatus,
      },
      {
        id: "action",
        sortable: false,
        label: <span className={classes.tableLabelCentered}>Åtgärd</span>,
        // eslint-disable-next-line react/no-unstable-nested-components
        formatBodyCell: ({ rowData }) => {
          const feedbackAction = requiresFeedback(rowData)

          if (feedbackAction) {
            return (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <FormControlLabel
                  labelPlacement="start"
                  control={<Switch />}
                  label="Återkopplat i Braive?"
                  onChange={(_, checked) =>
                    onFeedbackChangeEvent(rowData, checked)
                  }
                />
              </Box>
            )
          }

          const journalAction = requiresJournal(rowData)

          if (journalAction) {
            // @todo There should be a dialog allowing to end the treatment in case a patient completed all modules
            //    instead of sign journal event.
            return (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className={classes.actionButton}
                  onClick={() => onSignJournalEvent(rowData)}
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  Signera Journal
                </Button>
              </Box>
            )
          }

          const disconnectAction = canDisconnect(rowData)

          if (disconnectAction) {
            return (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography textAlign="center">
                  Överväg arkivering i Braive
                </Typography>
              </Box>
            )
          }

          const motivationAction = requiresMotivation(rowData)

          if (motivationAction) {
            return (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <FormControlLabel
                  labelPlacement="start"
                  control={<Switch />}
                  label="Uppmuntrat till aktivitet?"
                  onChange={(_, checked) => onMotivateEvent(rowData, checked)}
                />
              </Box>
            )
          }

          return <Typography textAlign="center">Ingen åtgärd krävs</Typography>
        },
      },
    ],
    [
      classes.tableLabelCentered,
      classes.actionButton,
      onFeedbackChangeEvent,
      onSignJournalEvent,
      onMotivateEvent,
    ]
  )

  const columnsCompleted = useMemo(
    () => columns.filter((column) => column.id !== "pause"),
    [columns]
  )

  const columnsNoAction = useMemo(
    () =>
      columns.map((column) => {
        if (column.id === "action") {
          return {
            ...column,
            // eslint-disable-next-line react/no-unstable-nested-components
            formatBodyCell: () => (
              <Typography textAlign="center">Ingen åtgärd krävs</Typography>
            ),
          }
        }

        return column
      }),
    [columns]
  )

  const patientsSegregated = useMemo(
    () =>
      patients.reduce((acc, item) => {
        let key = item.patientStatus

        // Completed overrides the session number rule.
        if (
          [ikbtStatus.Active, ikbtStatus.Inactive, ikbtStatus.Invited].includes(
            key
          )
        ) {
          // Render to be either action_required, no_action_required or invited.
          if (
            (item.sessionNumber !== 0 || key !== ikbtStatus.Active) &&
            requiresAction(item)
          ) {
            key = "action_required"
            // If key is ikbtStatus.Invited, keep the original patientStatus
          } else if (key !== ikbtStatus.Invited) {
            key = "no_action"
          }
        }

        if (!Array.isArray(acc[key])) {
          acc[key] = []
        }

        acc[key].push(item)

        return acc
      }, {}),
    [patients]
  )

  const openPanelHandler = useCallback(
    (panel) => () => {
      if (patientsSegregated[panel]?.length > 0) {
        setOpenedPanel(panel)
      }
    },
    [patientsSegregated]
  )

  useEffect(() => {
    // On patients change make sure to not keep opened panel if it's empty.
    if (!patientsSegregated[openedPanel]?.length) {
      defaultPanel.current = null
      setOpenedPanel()
    }
  }, [patientsSegregated, openedPanel])

  const isPanelOpen = (panel) => {
    if (
      !defaultPanel.current &&
      !openedPanel &&
      patientsSegregated[panel]?.length > 0
    ) {
      defaultPanel.current = panel
      setOpenedPanel(panel)

      return false
    }

    return openedPanel === panel
  }

  return (
    <>
      <Accordion
        disableGutters
        elevation={2}
        square
        variant="outlined"
        className={classes.accordion}
        expanded={isPanelOpen("action_required")}
        onChange={openPanelHandler("action_required")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <PatientTableTitle
            title="Kräver åtgärd"
            count={patientsSegregated.action_required?.length}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table
            rowIdKey="patientId"
            data={patientsSegregated.action_required ?? []}
            columns={columns}
            tableCellClassnames={classes.tableCell}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        elevation={2}
        square
        variant="outlined"
        className={classes.accordion}
        expanded={isPanelOpen(ikbtStatus.Invited)}
        onChange={openPanelHandler(ikbtStatus.Invited)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <PatientTableTitle
            title="INBJUDNA"
            count={patientsSegregated[ikbtStatus.Invited]?.length}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table
            rowIdKey="patientId"
            data={patientsSegregated[ikbtStatus.Invited] ?? []}
            columns={columns}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        elevation={2}
        square
        variant="outlined"
        className={classes.accordion}
        expanded={isPanelOpen("no_action")}
        onChange={openPanelHandler("no_action")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <PatientTableTitle
            title="INGEN ÅTGÄRD KRÄVS"
            count={patientsSegregated.no_action?.length}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table
            rowIdKey="patientId"
            data={patientsSegregated.no_action ?? []}
            columns={columnsNoAction}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        elevation={2}
        square
        variant="outlined"
        className={classes.accordion}
        expanded={isPanelOpen(ikbtStatus.Completed)}
        onChange={openPanelHandler(ikbtStatus.Completed)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <PatientTableTitle
            title="AVSLUTADE"
            count={patientsSegregated[ikbtStatus.Completed]?.length}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Typography variant="body1" className={classes.infoText}>
            Här visas patienter som är Arkiverade i Braive och inte kräver
            ytterligare åtgärd.
          </Typography>
          <Table
            rowIdKey="patientId"
            data={patientsSegregated[ikbtStatus.Completed] ?? []}
            columns={columnsCompleted}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
