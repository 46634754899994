import React from "react"
import { Controller } from "react-hook-form"
import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  label: {
    margin: theme.spacing(0),
    display: "flex",
    gap: theme.spacing(1),
    "& .MuiTypography-root": {
      fontSize: (props) => props.fontSize,
    },
  },
  checkbox: {
    padding: theme.spacing(0),
  },
}))

const Checkbox = ({
  name = "",
  label = "",
  control,
  defaultValue,
  disabled,
  fontSize,
}) => {
  const classes = useStyles({ fontSize })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={() => (
        <MuiFormControlLabel
          label={label}
          labelPlacement="end"
          className={classes.label}
          disabled={disabled}
          control={
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field }) => (
                <MuiCheckbox
                  className={classes.checkbox}
                  checked={field.value}
                  inputRef={field.ref}
                  onChange={(e) => field.onChange(e.target.checked)}
                  onBlur={field.onBlur}
                  color="primary"
                  disabled={disabled}
                  disableRipple
                />
              )}
            />
          }
        />
      )}
    />
  )
}

export default Checkbox
