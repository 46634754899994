import { Box, Fade, Hidden, Tooltip, Typography } from "@mui/material"
import { InfoOutlined } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import Checkbox from "../../../components/Checkbox"
import { employmentType } from "../../../utils/employmentType"

const useStyles = makeStyles((theme) => ({
  transformContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  transformContainerContent: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    width: "50%",
    padding: theme.spacing(1, 1, 0, 1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: theme.spacing(0),
    },
  },
  transformText: {
    fontSize: "0.83rem",
  },
}))

const AvailabilityTransformCheckbox = ({
  caregiverEmploymentType,
  control,
  isEditMode,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.transformContainer}>
      <Box className={classes.transformContainerContent}>
        {caregiverEmploymentType === employmentType.FIXED ? (
          <Typography className={classes.transformText}>
            Ändras automatiskt till Standard
          </Typography>
        ) : (
          <Checkbox
            control={control}
            name="availabilityTransform"
            label="Får ändras till Standard"
            disabled={isEditMode}
            fontSize="0.83rem"
          />
        )}
        <Hidden smDown>
          <Tooltip
            arrow
            title="48h före starttid blir tillgängligheten bokningsbar för alla patienter"
            placement="top"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 100 }}
          >
            <InfoOutlined
              sx={{
                fontSize: 16,
                color: (theme) => theme.palette.primary.main,
              }}
            />
          </Tooltip>
        </Hidden>
      </Box>
    </Box>
  )
}

export default AvailabilityTransformCheckbox
