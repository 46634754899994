const materials = {
  models: [
    {
      description: "Ambivalenskorset",
      url: "https://dinpsykolog.se/ambivalenskorset/",
    },
    {
      description: "Biopsykosocial modell",
      url: "https://dinpsykolog.se/biopsykosocial-modell/",
    },
    {
      description: "KBT (Känsla, tanke, beteende, kropp)",
      url: "https://dinpsykolog.se/kbt/",
    },
    {
      description: "Kognitiv modell av sviktande självkänsla",
      url: "https://dinpsykolog.se/kognitiv-modell-av-sviktande-sjalvkansla/",
    },
    {
      description: "Krav-kontroll-stöd-modell",
      url: "https://dinpsykolog.se/krav-kontroll-stod-modell/",
    },
    {
      description: "Livskompassen",
      url: "https://dinpsykolog.se/livskompassen/",
    },
    {
      description: "Modell för depression - kort",
      url: "https://dinpsykolog.se/depression-kort-modell/",
    },
    {
      description: "Modell för depression - lång",
      url: "https://dinpsykolog.se/depression-lang-modell/",
    },
    {
      description: "Modell för GAD",
      url: "https://dinpsykolog.se/modell-for-gad/",
    },
    {
      description: "Modell för panikcirkel",
      url: "https://dinpsykolog.se/modell-for-panikcirkel/",
    },
    {
      description: "Modell för social fobi",
      url: "https://dinpsykolog.se/modell-for-social-fobi/",
    },
    {
      description: "Modell för tvång",
      url: "https://dinpsykolog.se/modell-for-tvang/",
    },
    {
      description: "Ångestkurva",
      url: "https://dinpsykolog.se/angestkurva/",
    },
    {
      description: "Modell för grundantaganden",
      url: "https://dinpsykolog.se/modell-for-grundantaganden/",
    },
  ],
  psychoeducations: [
    {
      description: "Om ADHD",
      url: "https://dinpsykolog.se/vad-ar-adhd/",
    },
    {
      description: "Om autism",
      url: "https://dinpsykolog.se/om-autism/",
    },
    {
      description: "Om depression",
      url: "https://dinpsykolog.se/om-depression/",
    },
    {
      description: "Om dödsångest",
      url: "https://dinpsykolog.se/om-dodsangest/",
    },
    {
      description: "Om generell ångest",
      url: "https://dinpsykolog.se/om-generell-angest/",
    },
    {
      description: "Om hälsoångest",
      url: "https://dinpsykolog.se/vad-ar-halsoangest/",
    },
    {
      description: "Om insomni",
      url: "https://dinpsykolog.se/om-insomni/",
    },
    {
      description: "Om kriser",
      url: "https://dinpsykolog.se/om-kriser",
    },
    {
      description: "Om känslor",
      url: "https://dinpsykolog.se/om-kanslor/",
    },
    {
      description: "Om mindfulness",
      url: "https://dinpsykolog.se/om-mindfulness/",
    },
    {
      description: "Om panikångest",
      url: "https://dinpsykolog.se/om-panikangest/",
    },
    {
      description: "Om PTSD",
      url: "https://dinpsykolog.se/om-ptsd/",
    },
    {
      description: "Om social ångest",
      url: "https://dinpsykolog.se/om-social-angest/",
    },
    {
      description: "Om sorg",
      url: "https://dinpsykolog.se/psykoedukativa-texter-om-sorg/",
    },
    {
      description: "Om specifika fobier",
      url: "https://dinpsykolog.se/om-specifika-fobier/",
    },
    {
      description: "Om stress",
      url: "https://dinpsykolog.se/om-stress/",
    },
    {
      description: "Om tvång",
      url: "https://dinpsykolog.se/om-tvang/",
    },
    {
      description: "Om ångest",
      url: "https://dinpsykolog.se/om-angest/",
    },
  ],
  treatmentPrograms: {
    depressionProgram: [
      {
        description: "DEP Intro till behandling",
        url: "https://dinpsykolog.se/behandlingsprogram-for-depression-intro/",
      },
      {
        description: "DEP Modul 1 Om depression och dagbok",
        url: "https://dinpsykolog.se/behandlingsprogram-for-depression-modul-1/",
      },
      {
        description: "DEP Modul 2 Beteendeanalys och mål",
        url: "https://dinpsykolog.se/behandlingsprogram-for-depression-modul-2/",
      },
      {
        description: "DEP Modul 3 Beteendeaktivering",
        url: "https://dinpsykolog.se/behandlingsprogram-for-depression-modul-3/",
      },
      {
        description: "DEP Modul 4 Aktivering i 7 steg",
        url: "https://dinpsykolog.se/behandlingsprogram-for-depression-modul-4/",
      },
      {
        description: "DEP Modul 5 TRAP och TRAC",
        url: "https://dinpsykolog.se/behandlingsprogram-for-depression-modul-5/",
      },
      {
        description: "DEP Modul 6 Hantera grubbel",
        url: "https://dinpsykolog.se/behandlingsprogram-for-depression-modul-6/",
      },
      {
        description: "DEP Modul 7 Problemlösning",
        url: "https://dinpsykolog.se/behandlingsprogram-for-depression-modul-7/",
      },
      {
        description: "DEP Modul 8 Värderad riktning",
        url: "https://dinpsykolog.se/behandlingsprogram-for-depression-modul-8/",
      },
      {
        description: "DEP Modul 9 Vidmakthållandeplan",
        url: "https://dinpsykolog.se/behandlingsprogram-for-depression-modul-9/",
      },
    ],
    healthAnxietyProgram: [
      {
        description: "HÅ Intro till behandling",
        url: "https://dinpsykolog.se/behandlingsprogram-for-halsoangest-intro/",
      },
      {
        description: "HÅ Modul 1 Om hälsoångest och kartläggning",
        url: "https://dinpsykolog.se/behandlingsprogram-for-halsoangest-modul-1/",
      },
      {
        description: "HÅ Modul 2 Ohjälpsamma beteenden",
        url: "https://dinpsykolog.se/behandlingsprogram-for-halsoangest-modul-2/",
      },
      {
        description: "HÅ Modul 3 Målformulering",
        url: "https://dinpsykolog.se/behandlingsprogram-for-halsoangest-modul-3/",
      },
      {
        description: "HÅ Modul 4 Interoceptiv exponering",
        url: "https://dinpsykolog.se/behandlingsprogram-for-halsoangest-modul-4/",
      },
      {
        description: "HÅ Modul 5 Ångesthierarki",
        url: "https://dinpsykolog.se/behandlingsprogram-for-halsoangest-modul-5/",
      },
      {
        description: "HÅ Modul 6 Exponering (ERP)",
        url: "https://dinpsykolog.se/behandlingsprogram-for-halsoangest-modul-6/",
      },
      {
        description: "HÅ Modul 7 Imaginär exponering",
        url: "https://dinpsykolog.se/behandlingsprogram-for-halsoangest-modul-7/",
      },
      {
        description: "HÅ Modul 8 Vidmakthållandeplan",
        url: "https://dinpsykolog.se/behandlingsprogram-for-halsoangest-modul-8/",
      },
    ],
    emotionallyFocusedPrograms: [
      {
        description: "KF Intro till behandling 1",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-intro-1/",
      },
      {
        description: "KF Intro till behandling 2",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-intro-2/",
      },
      {
        description: "KF Modul 1 Beteendeanalys och mål",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-1/",
      },
      {
        description: "KF Modul 2 Om känslor och dagbok",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-2/",
      },
      {
        description: "KF Modul 3 Känslodagbok med SORK",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-3/",
      },
      {
        description: "KF Modul 4 Medveten närvaro",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-4/",
      },
      {
        description: "KF Modul 5 Undersök negativa tankar",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-5/",
      },
      {
        description: "KF Modul 6 Säkerhetsbeteenden",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-6/",
      },
      {
        description: "KF Modul 7 Alternativa beteenden",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-7/",
      },
      {
        description: "KF Modul 8 Interoceptiv exponering",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-8/",
      },
      {
        description: "KF Modul 9 Exponering in vivo",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-9/",
      },
      {
        description: "KF Modul 10 Vidmakthållandeplan",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-10/",
      },
    ],
    specificPhobiaProgram: [
      {
        description: "SF Intro till behandling",
        url: "https://dinpsykolog.se/behandlingsprogram-for-specifik-fobi-intro/",
      },
      {
        description: "SF Modul 1 Om fobi och kartläggning",
        url: "https://dinpsykolog.se/behandlingsprogram-for-specifik-fobi-modul-1/",
      },
      {
        description: "SF Modul 2 Beteendeanalys och mål",
        url: "https://dinpsykolog.se/behandlingsprogram-for-specifik-fobi-modul-2/",
      },
      {
        description: "SF Modul 3 Säkerhetsbeteenden",
        url: "https://dinpsykolog.se/behandlingsprogram-for-specifik-fobi-modul-3/",
      },
      {
        description: "SF Modul 4 Undersöka tankar",
        url: "https://dinpsykolog.se/behandlingsprogram-for-specifik-fobi-modul-4/",
      },
      {
        description: "SF Modul 5 Exponering",
        url: "https://dinpsykolog.se/behandlingsprogram-for-specifik-fobi-modul-5/",
      },
      {
        description: "SF Modul 6 Vidmakthållandeplan",
        url: "https://dinpsykolog.se/behandlingsprogram-for-specifik-fobi-modul-6/",
      },
    ],
    stressProgram: [
      {
        description: "STR Intro till behandling",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress/",
      },
      {
        description: "STR Modul 1 Om stress och kartläggning",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-1",
      },
      {
        description: "STR Modul 2 Beteendeanalys och mål",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-2/",
      },
      {
        description: "STR Modul 3 Återhämtning",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-3/",
      },
      {
        description: "STR Modul 4 Förbättra sömn",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-4/",
      },
      {
        description: "STR Modul 5 Värderad riktning",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-5/",
      },
      {
        description: "STR Modul 6 Exponering",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-6/",
      },
      {
        description: "STR Modul 7 Problemlösning",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-7/",
      },
      {
        description: "STR Modul 8 Kommunicera behov",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-8/",
      },
      {
        description: "STR Modul 9 Vidmakthållandeplan",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-9/",
      },
    ],
    sleepingProblemPrograms: [
      {
        description: "SÖM Intro till behandling",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-intro/",
      },
      {
        description: "SÖM Modul 1 Om sömn och sömndagbok",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-1/",
      },
      {
        description: "SÖM Modul 2 Beteendeanalys och mål",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-2/",
      },
      {
        description: "SÖM Modul 3 Sömnhygien",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-3/",
      },
      {
        description: "SÖM Modul 4 Stimuluskontroll",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-4/",
      },
      {
        description: "SÖM Modul 5 Sömnrestriktion",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-5/",
      },
      {
        description: "SÖM Modul 6 Progressiv avslappning",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-6/",
      },
      {
        description: "SÖM Modul 7 Vidmakthållandeplan",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-7/",
      },
    ],
    traumaPrograms: [
      {
        description: "WET skrivpass 1",
        url: "https://dinpsykolog.se/trauma-infor-skrivpass-1/",
      },
      {
        description: "WET skrivpass 2",
        url: "https://dinpsykolog.se/trauma-infor-skrivpass-2/",
      },
      {
        description: "WET skrivpass 3",
        url: "https://dinpsykolog.se/trauma-infor-skrivpass-3/",
      },
      {
        description: "WET skrivpass 4",
        url: "https://dinpsykolog.se/trauma-infor-skrivpass-4/",
      },
      {
        description: "WET skrivpass 5",
        url: "https://dinpsykolog.se/trauma-infor-skrivpass-5/",
      },
    ],
  },
  cbtWorksheets: [
    {
      description: "123-prioritering",
      url: "https://dinpsykolog.se/123-prioritering",
    },
    {
      description: "Aktivitetsschema",
      url: "https://dinpsykolog.se/aktivitetsschema/",
    },
    {
      description: "Andas i fyrkant",
      url: "https://dinpsykolog.se/andas-i-fyrkant/",
    },
    {
      description: "Andningsankaret",
      url: "https://dinpsykolog.se/andningsankaret/",
    },
    {
      description: "Behandlingsmål",
      url: "https://dinpsykolog.se/behandlingsmal/",
    },
    {
      description: "Beteendeanalys (SORK)",
      url: "https://dinpsykolog.se/beteendeanalys-sork/",
    },
    {
      description: "Beteendeexperiment",
      url: "https://dinpsykolog.se/kbt-arbetsblad-beteendeexperiment/",
    },
    {
      description: "Bussmetaforen",
      url: "https://dinpsykolog.se/kbt-arbetsblad-bussmetaforen/",
    },
    {
      description: "Exponering",
      url: "https://dinpsykolog.se/kbt-arbetsblad-exponering/",
    },
    {
      description: "Exponering för katastroftankar",
      url: "https://dinpsykolog.se/exponering-for-katastroftankar/",
    },
    {
      description: "Förslag på aktiviteter",
      url: "https://dinpsykolog.se/forslag-pa-aktiviteter/",
    },
    {
      description: "Hjälpsamma strategier vid ångest",
      url: "https://dinpsykolog.se/kbt-arbetsblad-hjalpsamma-strategier-vid-angest/",
    },
    {
      description: "IKBT - Välkomstinfo",
      url: "https://dinpsykolog.se/ikbt-intro/",
    },
    {
      description: "Intolerans för ovisshet",
      url: "https://dinpsykolog.se/intolerans-for-ovisshet/",
    },
    {
      description: "Jag-budskap",
      url: "https://dinpsykolog.se/jag-budskap/",
    },
    {
      description: "Kartlägga undvikanden och känslostyrt beteende",
      url: "https://dinpsykolog.se/kbt-arbetsblad-kartlagga-undvikanden-och-kanslostyrt-beteende/",
    },
    {
      description: "Kognitiv defusion",
      url: "https://dinpsykolog.se/kbt-arbetsblad-kognitiv-defusion/",
    },
    {
      description: "Krisplan",
      url: "https://dinpsykolog.se/kbt-arbetsblad-krisplan/",
    },
    {
      description: "Kroppsscanning",
      url: "https://dinpsykolog.se/kroppsscanning/",
    },
    {
      description: "Känslomässigt kontrastundvikande",
      url: "https://dinpsykolog.se/kbt-kanslomassigt-kontrastundvikande/",
    },
    {
      description: "Medkännande brevskrivande",
      url: "https://dinpsykolog.se/kbt-arbetsblad-medkannande-brevskrivande",
    },
    {
      description: "Möt känslan i kroppen",
      url: "https://dinpsykolog.se/mot-kanslan-i-kroppen/",
    },
    {
      description: "Nyckelbeteenden",
      url: "https://dinpsykolog.se/nyckelbeteenden/",
    },
    {
      description: "Om primära och sekundära känslor",
      url: "https://dinpsykolog.se/om-primara-och-sekundara-kanslor",
    },
    {
      description: "Orosdagbok",
      url: "https://dinpsykolog.se/orosdagbok/",
    },
    {
      description: "Orosstund",
      url: "https://dinpsykolog.se/orosstund/",
    },
    {
      description: "Positiva antaganden om oro",
      url: "https://dinpsykolog.se/positiva-antaganden-om-oro/",
    },
    {
      description: "Problemlösning",
      url: "https://dinpsykolog.se/problemlosning/",
    },
    {
      description: "Progressiv avslappning",
      url: "https://dinpsykolog.se/progressiv-avslappning/",
    },
    {
      description: "Registrera automatiska tankar",
      url: "https://dinpsykolog.se/registrera-automatiska-tankar/",
    },
    {
      description: "Självmedkänsla",
      url: "https://dinpsykolog.se/sjalvmedkansla/",
    },
    {
      description: "Sömndagbok",
      url: "https://dinpsykolog.se/somndagbok/",
    },
    {
      description: "Sömnhygienråd",
      url: "https://dinpsykolog.se/kbt-arbetsblad-somnhygienrad/",
    },
    {
      description: "Tacksamhetsdagbok",
      url: "https://dinpsykolog.se/tacksamhetsdagbok/",
    },
    {
      description: "Tankefällor",
      url: "https://dinpsykolog.se/tankefallor/",
    },
    {
      description: "Tolerans för osäkerhet",
      url: "https://dinpsykolog.se/tolerans-for-osakerhet/",
    },
    {
      description: "Trygg plats",
      url: "https://dinpsykolog.se/kbt-arbetsblad-trygg-plats",
    },
    {
      description: "Undersök automatiska tankar",
      url: "https://dinpsykolog.se/undersok-automatiska-tankar/",
    },
    {
      description: "Vidmakthållandeplan",
      url: "https://dinpsykolog.se/kbt-arbetsblad-vidmakthallandeplan/",
    },
    {
      description: "Ångesthierarki",
      url: "https://dinpsykolog.se/angesthierarki/",
    },
    {
      description: "Överskott och underskott",
      url: "https://dinpsykolog.se/overskott-och-underskott/",
    },
  ],
}

export default materials
