import { useTheme } from "@mui/material/styles"
import {
  FeedbackOutlined as FeedbackOutlinedIcon,
  Feedback as FeedbackFilledIcon,
} from "@mui/icons-material/"
import Tooltip from "@mui/material/Tooltip"

const SessionCountIconWithTooltip = ({ sessionCount }) => {
  const theme = useTheme()
  const feedbackIconStyle = {
    fontSize: "1.2rem",
    marginLeft: theme.spacing(0.5),
    verticalAlign: "middle",
    color: theme.palette.warning.dark,
  }
  const tooltip = {
    backgroundColor: "rgba(60, 58, 63, 0.95)",
    borderRadius: theme.spacing(0.5),
    color: "#F5EFF7",
    fontSize: "0.875rem",
  }

  const getToolTipTitle = () => {
    if (sessionCount > 15) {
      return `Maximal behandlingslängd (15 sessioner för video, 19 sessioner för IKBT med video) har uppnåtts.
         Avsluta behandlingen.`
    }
    if (sessionCount > 9) {
      return `Patient uppnår behandlingslängd på 10 sessioner. Bedöm om patient haft effekt av behandlingen.
         Maximal behandlingslängd är 15 sessioner för video, och 19 sessioner för IKBT med video.`
    }
    return ""
  }

  if (sessionCount > 15) {
    return (
      <Tooltip sx={tooltip} title={getToolTipTitle()}>
        <FeedbackFilledIcon sx={feedbackIconStyle} />
      </Tooltip>
    )
  }
  if (sessionCount > 9) {
    return (
      <Tooltip sx={tooltip} title={getToolTipTitle()}>
        <FeedbackOutlinedIcon sx={feedbackIconStyle} />
      </Tooltip>
    )
  }
  return null
}

export default SessionCountIconWithTooltip
