import Api from "api"
import isEmpty from "lodash.isempty"
import Cookie from "js-cookie"
import { getUser } from "api/getUser"
import { getExpertise } from "api/getExpertise"
import { updateCaregiver } from "api/updateCaregiver"
import { getActiveChats } from "api/getActiveChats"
import { getInactiveChats } from "api/getInactiveChats"
import { fileUpload } from "api/fileUpload"
import { updateUser } from "api/updateUser"
import { getChatEntries } from "api/getChatEntries"
import { postChatEntry } from "api/postChatEntry"
import { getSessionsByCaregiver } from "api/getSessionsByCaregiver"
import { imageUpload } from "api/imageUpload"
import { chatMessagesSeen } from "api/chatMessagesSeen"
import { getPatients } from "api/getPatients"
import { getKaddioForms } from "api/getKaddioForms"
import { difference } from "utils/compareObject"
import { connectToSocket } from "api/connectToSocket"
import { patchCaregiverAvailability } from "api/patchCaregiverAvailability"
import { patchCaregiverMaxPatients } from "api/patchCaregiverMaxPatients"
import { getCaregiverCalendarEvents } from "api/getCaregiverCalendarEvents"
import { getActiveUnreadMessagesCount } from "api/getActiveUnreadMessagesCount"
import { getInactiveUnreadMessagesCount } from "api/getInactiveUnreadMessagesCount"
import { getCaregiverTimeSlotsAvailable } from "api/getCaregiverTimeSlotsAvailable"
import { getCaregiverIkbtPatients } from "api/getCaregiverIkbtPatients"
import { updateIkbtPatientStatus } from "api/updateIkbtPatientStatus"
import { reactivateIkbtPatient } from "api/reactivateIkbtPatient"
import { motivateIkbtPatient } from "api/motivateIkbtPatient"
import { updateIkbtFeedback } from "api/updateIkbtFeedback"
import { entryType } from "utils/entryType"
import { defaultPagination } from "utils"
import { availabilityUpdateType as updateType } from "utils/availabilityUpdateType"
import { convertDatesConsideringDaylightSavingTime } from "utils/convertDatesConsideringDaylightSavingTime"
import { getFormattedDateAndTime } from "utils/getFormattedDateAndTime"
import config from "config"
import { isBefore, isSameDay, format, addMinutes } from "date-fns"

import {
  SET_USER,
  PUT_USER,
  SET_CAREGIVER,
  PUT_CAREGIVER,
  LOGOUT_USER,
  SET_INITIAL_LOADING,
  SET_TOKEN,
  SET_EXPERTISE,
  SET_CHATS,
  SET_PATIENTS,
  POST_MESSAGE,
  ALERT_SHOW,
  ALERT_HIDE,
  SET_INITIAL_MESSAGE,
  SET_USER_AVATAR,
  SET_SESSION_JOURNALIZED,
  SET_SESSIONS,
  SET_CHAT_MESSAGES_REQUEST,
  SET_CHAT_MESSAGES_SUCCESS,
  SET_CHAT_MESSAGES_ERROR,
  SET_CHAT_MESSAGES_PAGINATION,
  SET_CHAT_ACTIVE_UNREAD_MESSAGES_COUNT,
  SET_CHAT_INACTIVE_UNREAD_MESSAGES_COUNT,
  SET_KADDIO_FORMS,
  SET_CALENDAR_EVENTS,
  SET_AVAILABLE_TIME_SLOTS,
  SET_IKBT_PATIENTS,
  UPDATE_IKBT_PATIENT,
} from "redux/actionTypes"

export const showAlert = ({
  type,
  message = "",
  increasedFont = false,
  duration = 30000,
}) => {
  const alertTypes = (alertType) => {
    switch (alertType) {
      case "success":
      case "error":
      case "warning":
      case "info":
        return alertType
      default:
        return "success"
    }
  }

  function hideAlert() {
    return {
      type: ALERT_HIDE,
      payload: {
        show: false,
      },
    }
  }

  return {
    type: ALERT_SHOW,
    payload: {
      type: alertTypes(type),
      message,
      increasedFont,
      duration,
      show: true,
      hide: hideAlert,
    },
  }
}

const setUser = (data) => (dispatch) => {
  dispatch({
    type: PUT_USER,
    payload: data,
  })
}

const setCaregiver = (data) => (dispatch) => {
  dispatch({
    type: PUT_CAREGIVER,
    payload: data,
  })
}

export const updateForm = (data) => (dispatch, getState) => {
  const { user, caregiver } = getState()
  const { userId } = user
  const { email, phoneNumber, activeInMatchmaking, maxPatients, ...form } = data
  const userData = { email, phoneNumber }
  const caregiverData = { ...form }

  const userDiff = difference(userData, user)
  const caregiverDiff = difference(caregiverData, caregiver)
  const availabilityDiff = difference(
    { activeInMatchmaking },
    { activeInMatchmaking: user.activeInMatchmaking }
  )
  const maxPatientsDiff = difference(
    { maxPatients },
    { maxPatients: user.maxPatients }
  )

  const formMap = [
    {
      query: () => updateUser(userId, userData),
      action: setUser(userData),
      diff: userDiff,
    },
    {
      query: () => updateCaregiver(userId, caregiverData),
      action: setCaregiver(caregiverData),
      diff: caregiverDiff,
    },
    {
      query: () =>
        patchCaregiverAvailability(userId, { available: activeInMatchmaking }),
      action: setUser(availabilityDiff),
      diff: availabilityDiff,
    },
    {
      query: () => patchCaregiverMaxPatients(userId, { maxPatients }),
      action: setUser(maxPatientsDiff),
      diff: maxPatientsDiff,
    },
  ]

  const [queries, actions] = formMap.reduce(
    (prev, current) => {
      const [prevQueries, prevActions] = prev

      if (!isEmpty(current?.diff)) {
        return [
          [...prevQueries, current?.query()],
          [...prevActions, current?.action],
        ]
      }

      return prev
    },
    [[], []]
  )

  if (!isEmpty(queries)) {
    Promise.allSettled(queries).then((responses) => {
      const fulfilledResponses = responses.filter(({ status }, index) => {
        if (status === "fulfilled") {
          return true
        }

        // Remove rejected actions
        actions.splice(index, 1)
        return false
      })

      if (isEmpty(fulfilledResponses)) {
        dispatch(
          showAlert({
            type: "error",
            message: "Något gick fel, misslyckades att spara",
          })
        )
        return null
      }

      actions.forEach((action) => {
        dispatch(action)
      })

      const shouldShowWarning = responses.some(
        ({ status }) => status === "rejected"
      )

      if (shouldShowWarning) {
        dispatch(
          showAlert({
            type: "warning",
            message: "Något gick fel, all information blev inte sparad",
          })
        )
      } else {
        dispatch(showAlert({ type: "success", message: "Profil sparad" }))
      }
    })
  }
}
export const setInitialLoading = (loadingState) => ({
  type: SET_INITIAL_LOADING,
  payload: loadingState,
})

export const fetchExpertise = () => (dispatch) => {
  getExpertise().then(({ data }) => {
    dispatch({
      type: SET_EXPERTISE,
      payload: data,
    })
  })
}

export const fetchUser = (data) => (dispatch) => {
  const { token, id } = data
  Api.defaults.headers.common.Authorization = `Bearer ${token}`
  dispatch({
    type: SET_TOKEN,
    payload: token,
  })
  Promise.all([getUser(id), connectToSocket()])
    .then((response) => {
      const [{ data: user }] = response
      const { shortDesc, longDesc, expertise, minutesUntilBooking, ...rest } =
        user
      const userData = { ...rest }
      const caregiverData = {
        shortDesc,
        longDesc,
        expertise,
        minutesUntilBooking,
      }

      dispatch({
        type: SET_CAREGIVER,
        payload: caregiverData,
      })
      dispatch({
        type: SET_USER,
        payload: userData,
      })
    })
    .catch(() => {
      // when fail to fetch user redirect back to login screen
      dispatch(setInitialLoading(false))
    })
}

export const fetchPatientsAndSessions = (userId) => (dispatch) => {
  dispatch({
    type: SET_SESSIONS,
    payload: {
      loading: true,
      error: false,
    },
  })
  return Promise.all([getPatients(userId), getSessionsByCaregiver(userId)])
    .then((response) => {
      const [{ data: patients }, { data: sessions }] = response

      if (isEmpty(sessions)) {
        dispatch({
          type: SET_SESSIONS,
          payload: {
            loading: false,
            error: false,
            active: [],
            past: [],
          },
        })
      } else {
        dispatch({
          type: SET_SESSIONS,
          payload: {
            loading: false,
            error: false,
            active: sessions.active,
            past: sessions.past,
          },
        })
        dispatch({
          type: SET_PATIENTS,
          payload: patients,
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: SET_SESSIONS,
        payload: {
          loading: false,
          error: true,
          active: [],
          past: [],
        },
      })
    })
}

const sortActiveChatInstances = (instances) =>
  instances.sort(
    (
      {
        fromUserId: fromUserId1,
        userId: userId1,
        systemEntry: systemEntry1,
        notify: notify1,
        seen: seen1,
        lastMessageDate: lastMessageDate1,
        userFullName: userFullName1,
      },
      {
        fromUserId: fromUserId2,
        userId: userId2,
        systemEntry: systemEntry2,
        notify: notify2,
        seen: seen2,
        lastMessageDate: lastMessageDate2,
        userFullName: userFullName2,
      }
    ) => {
      const hasUnreadMessages1 =
        fromUserId1 === userId1 && (!systemEntry1 || notify1) && !seen1
      const hasUnreadMessages2 =
        fromUserId2 === userId2 && (!systemEntry2 || notify2) && !seen2

      if (hasUnreadMessages1 || hasUnreadMessages2) {
        if (hasUnreadMessages1 && hasUnreadMessages2) {
          return Date.parse(lastMessageDate2) - Date.parse(lastMessageDate1)
        }
        return hasUnreadMessages2 - hasUnreadMessages1
      }

      return userFullName1.localeCompare(userFullName2, "sv")
    }
  )

const sortInactiveChatInstances = (instances) =>
  instances.sort((firstInstance, secondInstance) => {
    const firstInstanceLatestActivity = Math.max(
      Date.parse(
        firstInstance.canceled
          ? firstInstance.bookedAt
          : firstInstance.sessionEnd
      ),
      Date.parse(firstInstance.lastMessageDate)
    )

    const secondInstanceLatestActivity = Math.max(
      Date.parse(
        secondInstance.canceled
          ? secondInstance.bookedAt
          : secondInstance.sessionEnd
      ),
      Date.parse(secondInstance.lastMessageDate)
    )

    return secondInstanceLatestActivity - firstInstanceLatestActivity
  })

export const fetchActiveChats = () => (dispatch) => {
  dispatch({
    type: SET_CHATS,
    payload: {
      loading: true,
      error: false,
    },
  })

  return Promise.all([getActiveChats()])
    .then((response) => {
      const [{ data: activeInstances }] = response

      if (isEmpty(activeInstances)) {
        dispatch({
          type: SET_CHATS,
          payload: {
            loading: false,
            error: false,
            activeInstances: [],
          },
        })
      } else {
        dispatch({
          type: SET_CHATS,
          payload: {
            loading: false,
            error: false,
            activeInstances: sortActiveChatInstances(activeInstances),
          },
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: SET_CHATS,
        payload: {
          loading: false,
          error: true,
          activeInstances: [],
        },
      })
    })
}

export const fetchInactiveChats = () => (dispatch) => {
  dispatch({
    type: SET_CHATS,
    payload: {
      inactiveInstancesLoading: true,
      error: false,
    },
  })
  return getInactiveChats()
    .then((response) => {
      const { data: inactiveInstances } = response

      if (isEmpty(inactiveInstances)) {
        dispatch({
          type: SET_CHATS,
          payload: {
            inactiveInstancesLoading: false,
            error: false,
            inactiveInstances: [],
          },
        })
      } else {
        dispatch({
          type: SET_CHATS,
          payload: {
            inactiveInstancesLoading: false,
            error: false,
            inactiveInstances: sortInactiveChatInstances(inactiveInstances),
          },
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: SET_CHATS,
        payload: {
          inactiveInstancesLoading: false,
          error: true,
          inactiveInstances: [],
        },
      })
    })
}

export const fetchChatEntries = (chatId) => (dispatch, getState) => {
  const {
    user: { userId },
    chats: { activeInstances, inactiveInstances },
  } = getState()

  getChatEntries(chatId)
    .then((response) => {
      const {
        data: { data: chatEntries, totalCount },
      } = response

      const activeInstanceIndex = activeInstances.findIndex(
        (instance) => instance.chatId === chatId
      )
      const isActiveInstance = activeInstanceIndex !== -1
      const modifiedInstances = isActiveInstance
        ? [...activeInstances]
        : [...inactiveInstances]
      const instanceIndex = isActiveInstance
        ? activeInstanceIndex
        : inactiveInstances.findIndex((instance) => instance.chatId === chatId)
      if (instanceIndex !== -1) {
        modifiedInstances[instanceIndex].seen = true

        dispatch({
          type: isActiveInstance
            ? SET_CHAT_ACTIVE_UNREAD_MESSAGES_COUNT
            : SET_CHAT_INACTIVE_UNREAD_MESSAGES_COUNT,
          payload: modifiedInstances.filter(
            (instance) =>
              !instance.unmatched &&
              instance.fromUserId === instance.userId &&
              (!instance.systemEntry || instance.notify) &&
              !instance.seen
          ).length,
        })

        dispatch({
          type: SET_CHATS,
          payload: {
            loading: false,
            error: false,
            messages: chatEntries,
            messagesCount: totalCount,
            switchChatLoading: false,
            activeInstances: isActiveInstance
              ? sortActiveChatInstances(modifiedInstances)
              : activeInstances,
            inactiveInstances: isActiveInstance
              ? inactiveInstances
              : modifiedInstances,
          },
        })

        chatMessagesSeen(chatId, userId)
      }
    })
    .catch((error) => {
      dispatch({
        type: SET_CHATS,
        payload: {
          loading: false,
          error: true,
          messages: [],
          switchChatLoading: false,
        },
      })
    })
}

export const fetchEntries = (chatId, pagination) => async (dispatch) => {
  try {
    dispatch({ type: SET_CHAT_MESSAGES_REQUEST })
    const { data } = await getChatEntries(chatId, pagination)

    dispatch({
      type: SET_CHAT_MESSAGES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({ type: SET_CHAT_MESSAGES_ERROR, payload: error })
  }
}

export const fetchAvailableTimeSlots =
  (meetingStart) => async (dispatch, getState) => {
    const {
      user: { userId },
    } = getState()
    const { data } = await getCaregiverTimeSlotsAvailable(userId)
    const availabilities = data?.available
      ? [...convertDatesConsideringDaylightSavingTime(data.available)]
      : []
    const availabilitiesGroupedByDate = availabilities.reduce(
      (availability, slot) => {
        if (
          meetingStart &&
          (isSameDay(new Date(slot.start), new Date(meetingStart)) ||
            isBefore(new Date(slot.start), new Date(meetingStart)))
        ) {
          return availability
        }
        const dayDate = format(slot.start, "MMM d yyyy")
        if (!availability[dayDate]) {
          availability[dayDate] = []
        }
        availability[dayDate].push({
          start: slot.start,
          end: slot.end,
          timeZone: slot.timeZone,
          visibilityType: slot.visibilityType,
        })
        return availability
      },
      {}
    )
    const timeSlotsGroupedByDate = Object.keys(availabilitiesGroupedByDate).map(
      (dayDate) => ({
        dayDate,
        slots: availabilitiesGroupedByDate[dayDate],
      })
    )
    dispatch({
      type: SET_AVAILABLE_TIME_SLOTS,
      payload: timeSlotsGroupedByDate,
    })
  }

export const fetchKaddioForms = () => (dispatch) => {
  getKaddioForms().then(({ data: forms }) => {
    if (forms) {
      const formIdsWithoutPreview = []
      forms.forEach((form) => {
        switch (form.name) {
          case "AAI - Appearance Anxiety Inventory":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/AAI-Appearance-Anxiety-Inventory.png"
            break
          case "ACQ - Agoraphobic Cognitions Questionnaire":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/ACQ-Agoraphobic-Cognitions-Questionnaire.png"
            break
          case "Anamnesfrågor":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/Anamnesfragor.png"
            break
          case "AUDIT - Alcohol Use Disorders Identification Test":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/07/AUDIT-Alcohol-Use-Disorders-Identification-Test.png"
            break
          case "BSQ - Body Sensations Questionnaire":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/BSQ-Body-Sensations-Questionnaire.png"
            break
          case "CLQ - Klaustrofobiformuläret":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/CLQ-Klaustrofobi.png"
            break
          case "DSM-5 Självskattning av aktuella symtom":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/DSM-5-Sjalvskattning-av-aktuella-symtom.png"
            break
          case "EmetQ kortversion - Emetophobia Questionnaire":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/EmetQ-kortversion-1.png"
            break
          case "GAD-7 - Generalised Anxiety Disorder 7-item scale":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/GAD-7-Generalised-Anxiety-Disorder-7-item-scale.png"
            break
          case "IES-R - Impact of Event Scale–Revised":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/IES-R-Impact-of-Event-Scale–Revised.png"
            break
          case "ISI – Insomnia Severity Index":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/ISI-Insomnia-Severity-Index.png"
            break
          case "KEDS - Karolinska Exhaustion Disorder Scale 9":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/KEDS-Karolinska-Exhaustion-Disorder-Scale-9.png"
            break
          case "MADRS-S - Montgomery Åsberg Depression Rating Scale":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/MADRS-S-Montgomery-Asberg-Depression-Rating-Scale.png"
            break
          case "MDQ - Screening för bipolaritet":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/MDQ-Screening-for-bipolaritet.png"
            break
          case "OCI-R - Obsessive Compulsive Inventory- Revised":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/OCI-R-Obsessive-Compulsive-Inventory-Revised.png"
            break
          case "PDSS-SR - Panic Disorder Severity Scale - Self Rated":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/PDSS-SR-Panic-Disorder-Severity-Scale-Self-Rated.png"
            break
          case "PHQ-9 - Patient Health Questionnaire":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/PHQ-9-Patient-Health-Questionnaire.png"
            break
          case "PSS-14 - Perceived Stress Scale":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/PSS-14-Perceived-Stress-Scale.png"
            break
          case "PSWQ - Penn State Worry Questionnaire":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/PSWQ-Penn-State-Worry-Questionnaire.png"
            break
          case "SHAI-14 - Short Health Anxiety Inventory":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/SHAI-14-Short-Health-Anxiety-Inventory.png"
            break
          case "SIAS - Social Interaction Anxiety Scale":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/SIAS-Social-Interaction-Anxiety-Scale.png"
            break
          case "SPS - Social Phobia Scale":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/SPS-Social-Phobia-Scale.png"
            break
          default:
            formIdsWithoutPreview.push(form._id)
            break
        }
      })
      dispatch({
        type: SET_KADDIO_FORMS,
        payload: forms.filter(
          ({ _id }) => !formIdsWithoutPreview.includes(_id)
        ),
      })
    }
  })
}

export const fetchUnreadMessagesCount = () => async (dispatch) => {
  Promise.all([
    getActiveUnreadMessagesCount(),
    getInactiveUnreadMessagesCount(),
  ])
    .then((response) => {
      const [
        { data: activeUnreadMessagesCount },
        { data: inactiveUnreadMessagesCount },
      ] = response

      dispatch({
        type: SET_CHAT_ACTIVE_UNREAD_MESSAGES_COUNT,
        payload: activeUnreadMessagesCount,
      })
      dispatch({
        type: SET_CHAT_INACTIVE_UNREAD_MESSAGES_COUNT,
        payload: inactiveUnreadMessagesCount,
      })
    })
    .catch(() => {
      dispatch({
        type: SET_CHAT_ACTIVE_UNREAD_MESSAGES_COUNT,
        payload: 0,
      })
      dispatch({
        type: SET_CHAT_INACTIVE_UNREAD_MESSAGES_COUNT,
        payload: 0,
      })
    })
}

export const setChatMessagesPagination = (data) => (dispatch) => {
  dispatch({
    type: SET_CHAT_MESSAGES_PAGINATION,
    payload: data,
  })
}

export const postMessage = (chatId, body) => (dispatch, getState) =>
  postChatEntry({ ...body, chatId })
    .then(({ data }) => {
      const chatMessage = {
        ...data[0],
        chatId,
        ...body,
        type: entryType.DEFAULT,
      }
      const {
        chats: { activeInstances, inactiveInstances, activeChat },
      } = getState()

      const activeInstanceIndex = activeInstances.findIndex(
        (instance) => instance.chatId === chatId
      )
      const isActiveInstance = activeInstanceIndex !== -1
      const modifiedInstances = isActiveInstance
        ? [...activeInstances]
        : [...inactiveInstances]
      const instanceIndex = isActiveInstance
        ? activeInstanceIndex
        : inactiveInstances.findIndex((instance) => instance.chatId === chatId)
      if (instanceIndex !== -1) {
        modifiedInstances[instanceIndex].lastMessage = chatMessage.data
        modifiedInstances[instanceIndex].lastMessageDate = chatMessage.createdAt
        modifiedInstances[instanceIndex].fromUserId = chatMessage.fromUserId
        modifiedInstances[instanceIndex].notify = true
        modifiedInstances[instanceIndex].systemEntry = false

        dispatch({
          type: SET_INITIAL_MESSAGE,
          payload: isActiveInstance
            ? {
                activeInstances: modifiedInstances,
              }
            : {
                inactiveInstances: sortInactiveChatInstances(modifiedInstances),
              },
        })
      }

      if (chatMessage.chatId === activeChat?.chatId) {
        dispatch({
          type: POST_MESSAGE,
          payload: chatMessage,
        })
      }
      dispatch(setChatMessagesPagination(defaultPagination))

      return Promise.resolve()
    })
    .catch((error) => {
      dispatch(
        showAlert({
          type: "error",
          message:
            "Ditt meddelande gick tyvärr inte att skicka, försök gärna igen",
        })
      )
      return Promise.reject(error)
    })

export const fetchSessions = (caregiverId) => (dispatch) => {
  dispatch({
    type: SET_SESSIONS,
    payload: {
      loading: true,
      error: false,
    },
  })
  getSessionsByCaregiver(caregiverId)
    .then(({ data }) => {
      if (isEmpty(data)) {
        dispatch({
          type: SET_SESSIONS,
          payload: {
            loading: false,
            error: false,
            active: [],
            past: [],
          },
        })
      } else {
        dispatch({
          type: SET_SESSIONS,
          payload: {
            loading: false,
            error: false,
            active: data.active,
            past: data.past,
          },
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: SET_SESSIONS,
        payload: {
          loading: false,
          error: true,
          active: [],
          past: [],
        },
      })
    })
}

export const setIncomingMessage = (message) => async (dispatch, getState) => {
  const {
    chats: { error, activeChat, activeInstances, inactiveInstances },
    user: { userId },
  } = getState()
  let currentActiveChat = activeChat
  let currentActiveInstances = activeInstances
  let currentInactiveInstances = inactiveInstances
  const isActiveChatMessage = message.chatId === currentActiveChat?.chatId

  if (!error) {
    if (
      !activeInstances?.some(({ chatId }) => chatId === message.chatId) &&
      !inactiveInstances?.some(({ chatId }) => chatId === message.chatId)
    ) {
      try {
        await dispatch(fetchActiveChats())
        await dispatch(fetchInactiveChats())
        const {
          chats: {
            activeChat: fetchedActiveChat,
            activeInstances: fetchedActiveInstances,
            inactiveInstances: fetchedInactiveInstances,
          },
        } = getState()
        currentActiveChat = fetchedActiveChat
        currentActiveInstances = fetchedActiveInstances
        currentInactiveInstances = fetchedInactiveInstances
      } catch (err) {
        return
      }
    }

    const activeInstanceIndex = currentActiveInstances.findIndex(
      (instance) => instance.chatId === message.chatId
    )
    const isActiveInstance = activeInstanceIndex !== -1
    const modifiedInstances = isActiveInstance
      ? [...currentActiveInstances]
      : [...currentInactiveInstances]
    const instanceIndex = isActiveInstance
      ? activeInstanceIndex
      : currentInactiveInstances.findIndex(
          (instance) => instance.chatId === message.chatId
        )

    if (instanceIndex !== -1) {
      modifiedInstances[instanceIndex].lastMessage = message.data
      modifiedInstances[instanceIndex].lastMessageDate = message.createdAt
      modifiedInstances[instanceIndex].fromUserId = message.fromUserId
      modifiedInstances[instanceIndex].notify = message.notify
      modifiedInstances[instanceIndex].systemEntry = message.systemEntry
      modifiedInstances[instanceIndex].seen = isActiveChatMessage

      dispatch({
        type: SET_INITIAL_MESSAGE,
        payload: isActiveInstance
          ? {
              activeInstances: sortActiveChatInstances(modifiedInstances),
            }
          : {
              inactiveInstances: sortInactiveChatInstances(modifiedInstances),
            },
      })
      if (!isActiveChatMessage) {
        dispatch({
          type: isActiveInstance
            ? SET_CHAT_ACTIVE_UNREAD_MESSAGES_COUNT
            : SET_CHAT_INACTIVE_UNREAD_MESSAGES_COUNT,
          payload: modifiedInstances.filter(
            (instance) =>
              !instance.unmatched &&
              instance.fromUserId === instance.userId &&
              (!instance.systemEntry || instance.notify) &&
              !instance.seen
          ).length,
        })
      } else {
        dispatch({
          type: POST_MESSAGE,
          payload: message,
        })

        chatMessagesSeen(message.chatId, userId)
      }
    }
  }
}

export const setLastMessageSeen =
  ({ chatId, userId }) =>
  (dispatch, getState) => {
    const {
      chats: { messages, activeChat },
    } = getState()

    if (activeChat?.chatId === chatId && activeChat?.userId === userId) {
      const seenMessages = messages.map((msg) => {
        const isCaregiverOrPatientMessage = [
          entryType.DEFAULT,
          entryType.SYSTEM,
        ].includes(msg.type)

        return { ...msg, seen: isCaregiverOrPatientMessage ? true : msg.seen }
      })

      dispatch({
        type: SET_CHATS,
        payload: {
          messages: seenMessages,
        },
      })
    }
  }

export const setActiveChat = (instance) => async (dispatch) => {
  dispatch({
    type: SET_CHATS,
    payload: {
      activeChat: { ...instance },
      switchChatLoading: true,
    },
  })
}

export const resetActiveChat = () => (dispatch) => {
  dispatch({
    type: SET_CHATS,
    payload: {
      messages: [],
      activeChat: null,
    },
  })
}

export const setActiveChatId = (chatId) => async (dispatch, getState) => {
  const {
    chats: { activeInstances, inactiveInstances, activeChat },
  } = getState()
  if (activeChat?.chatId !== chatId) {
    let currentInstances = [...activeInstances, ...inactiveInstances]

    if (!currentInstances?.some(({ chatId: id }) => id === chatId)) {
      try {
        await dispatch(fetchActiveChats())
        await dispatch(fetchInactiveChats())
        const {
          chats: {
            activeInstances: fetchedActiveInstances,
            inactiveInstances: fetchedInactiveInstances,
          },
        } = getState()
        currentInstances = [
          ...fetchedActiveInstances,
          ...fetchedInactiveInstances,
        ]
      } catch (err) {
        return
      }
    }

    const instance = currentInstances?.find(({ chatId: id }) => id === chatId)

    if (instance?.userId) {
      dispatch({
        type: SET_CHATS,
        payload: {
          activeChat: { ...instance },
          switchChatLoading: true,
        },
      })
    }
  }
}

export const setUserAvatar = (userId, file) => (dispatch) => {
  imageUpload(userId, file)
    .then(({ data }) => {
      const { url } = data
      dispatch({
        type: SET_USER_AVATAR,
        payload: url,
      })
      dispatch(showAlert({ type: "success", message: "Avatar sparad" }))
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data || "Något gick fel med bilduppladdningen"

      dispatch(showAlert({ type: "error", message: errorMessage }))
    })
}

export const uploadFile = (file) => (dispatch) => {
  const maxSize = 10 * 1024 * 1024 // 10MB
  if (file.size > maxSize) {
    dispatch(showAlert({ type: "error", message: "Filen är för stor" }))
    return
  }
  return fileUpload(file)
    .then(({ data }) => {
      dispatch(showAlert({ type: "success", message: "Fil uppladdad" }))
      return data.url
    })
    .catch((err) => {
      if (err.isAxiosError) {
        let message
        switch (err.response?.status) {
          case 422:
            message = "Filtyp som inte stöds"
            break
          case 400:
            message = "Filen är för stor"
            break
          default:
            message = "Något dåligt hände"
        }
        dispatch(showAlert({ type: "error", message }))
      }
    })
}

export const setSessionJournalized =
  (sessionId, isSigned) => (dispatch, getState) => {
    const { sessions, ikbtPatients } = getState()

    const newSessions = sessions.past.map((el) =>
      el.id === sessionId
        ? {
            ...el,
            journalized: isSigned,
          }
        : el
    )

    // Also update IKBT patients accordingly.
    const newPatients = ikbtPatients.map((patient) => {
      if (!patient.nonJournalizedSessionIds?.includes(sessionId)) {
        return patient
      }

      const newPatient = {
        ...patient,
      }

      newPatient.nonJournalizedSessionIds =
        patient.nonJournalizedSessionIds.filter((id) => id !== sessionId)

      return newPatient
    })

    dispatch({
      type: SET_SESSION_JOURNALIZED,
      payload: newSessions,
    })

    dispatch({
      type: SET_IKBT_PATIENTS,
      payload: newPatients,
    })
  }

export const removeUserCredentials = () => {
  Cookie.remove("caregiver", { domain: config.cookie_path })
  sessionStorage.clear()
  return { type: LOGOUT_USER }
}

export const fetchCalendarEvents = (caregiverId) => (dispatch) => {
  getCaregiverCalendarEvents(caregiverId).then(({ data }) => {
    if (data) {
      dispatch({
        type: SET_CALENDAR_EVENTS,
        payload: {
          bookings: [
            ...data.bookings.map(({ start, end, ...rest }) => ({
              ...rest,
              start: new Date(start),
              end: new Date(end),
            })),
          ],
          available: [
            ...convertDatesConsideringDaylightSavingTime(data.available),
          ],
        },
      })
    }
  })
}

export const fetchIKBTPatients = () => (dispatch) => {
  getCaregiverIkbtPatients()
    .then(({ data }) => {
      dispatch({
        type: SET_IKBT_PATIENTS,
        payload: data,
      })
    })
    .catch((error) => {
      dispatch(
        showAlert({
          type: "error",
          message: error.message,
        })
      )
    })
}

export const updateIKBTPatient = (patientId, update) => (dispatch) => {
  dispatch({
    type: UPDATE_IKBT_PATIENT,
    payload: {
      patientId,
      update,
    },
  })
}

export const updateIKBTPatientStatus = (patientId, status) => (dispatch) => {
  updateIkbtPatientStatus(patientId, status)
    .then(() => {
      updateIKBTPatient(patientId, {
        patientStatus: status,
      })(dispatch)
    })
    .catch((error) => {
      dispatch(
        showAlert({
          type: "error",
          message: error.message,
        })
      )
    })
}

export const reactivateIKBTPatient = (patientId) => (dispatch) => {
  reactivateIkbtPatient(patientId)
    .then(() => {
      // Refetch patients after reactivation.
      fetchIKBTPatients()(dispatch)
    })
    .catch((error) => {
      dispatch(
        showAlert({
          type: "error",
          message: error.message,
        })
      )
    })
}

export const motivateIKBTPatient = (patientId) => (dispatch) => {
  motivateIkbtPatient(patientId)
    .then(() => {
      // Refetch patients after motivation.
      fetchIKBTPatients()(dispatch)
    })
    .catch((error) => {
      dispatch(
        showAlert({
          type: "error",
          message: error.message,
        })
      )
    })
}

export const updateIKBTFeedback = (patientId) => (dispatch) => {
  updateIkbtFeedback(patientId)
    .then(() => {
      updateIKBTPatient(patientId, {
        sessionFeedback: true,
      })(dispatch)
    })
    .catch((error) => {
      dispatch(
        showAlert({
          type: "error",
          message: error.message,
        })
      )
    })
}

export const addCalendarAvailability =
  (newAvailability, isOwnBookingType) => (dispatch, getState) => {
    const { calendarEvents } = getState()
    dispatch({
      type: SET_CALENDAR_EVENTS,
      payload: {
        ...calendarEvents,
        available: [
          ...calendarEvents.available,
          ...convertDatesConsideringDaylightSavingTime(newAvailability),
        ],
      },
    })
    dispatch(
      showAlert({
        type: "success",
        message: `${
          isOwnBookingType ? "Egen bokning" : "Tillgänglighet"
        } sparad`,
      })
    )
  }

export const editCalendarAvailability =
  (editedAvailabilityAndAvailabilitiesUnderMeetings) =>
  (dispatch, getState) => {
    const { calendarEvents } = getState()
    const [{ id: editedAvailabilityId }] =
      editedAvailabilityAndAvailabilitiesUnderMeetings
    const calendarAvailabilitiesWithoutEditedAvailability =
      calendarEvents.available.filter(({ id }) => id !== editedAvailabilityId)
    dispatch({
      type: SET_CALENDAR_EVENTS,
      payload: {
        ...calendarEvents,
        available: [
          ...calendarAvailabilitiesWithoutEditedAvailability,
          ...convertDatesConsideringDaylightSavingTime(
            editedAvailabilityAndAvailabilitiesUnderMeetings
          ),
        ],
      },
    })
    dispatch(showAlert({ type: "success", message: "Tillgänglighet sparad" }))
  }

export const removeCalendarAvailability =
  (
    availabilityId,
    availabilityStartDate,
    availabilityUpdateType,
    isOwnBookingType,
    availabilitiesUnderMeetings
  ) =>
  (dispatch, getState) => {
    const { calendarEvents } = getState()

    const getFilteredAvailabilities = () => {
      switch (availabilityUpdateType) {
        case updateType.SINGLE:
          return calendarEvents.available.filter(
            ({ id, start }) =>
              id !== availabilityId || start !== availabilityStartDate
          )
        case updateType.FOLLOWING:
          return calendarEvents.available.filter(
            ({ id, start }) =>
              id !== availabilityId || start < availabilityStartDate
          )
        default:
          return calendarEvents.available.filter(
            ({ id }) => id !== availabilityId
          )
      }
    }
    dispatch({
      type: SET_CALENDAR_EVENTS,
      payload: {
        ...calendarEvents,
        available: availabilitiesUnderMeetings?.length
          ? [
              ...getFilteredAvailabilities(),
              ...convertDatesConsideringDaylightSavingTime(
                availabilitiesUnderMeetings
              ),
            ]
          : getFilteredAvailabilities(),
      },
    })
    dispatch(
      showAlert({
        type: "success",
        message: `${
          isOwnBookingType ? "Egen bokning" : "Tillgänglighet"
        } raderad`,
      })
    )
  }

export const handlePatientMeetingEvent =
  (data, eventType) => (dispatch, getState) => {
    const {
      user: { userId },
      availableTimeSlots,
      calendarEvents,
    } = getState()
    const { meetingStart, userId: patientId } = data
    const meetingStartDate = new Date(meetingStart)
    let patient = patientId?.slice(0, 5)

    if (eventType === "meeting: canceled" && calendarEvents?.bookings?.length) {
      const patientBooking = calendarEvents.bookings.find(
        (booking) => booking.patientId === patientId
      )
      if (patientBooking) {
        patient = patientBooking.title
      }
    }

    const message =
      eventType === "meeting: booked"
        ? `Du fick en ny bokning ${getFormattedDateAndTime(meetingStartDate)}`
        : `Videosamtalet ${getFormattedDateAndTime(
            meetingStartDate
          )} avbokades av ${patient}`

    if (availableTimeSlots) {
      if (eventType === "meeting: booked") {
        const filteredAvailableTimeSlots = availableTimeSlots.map((item) => ({
          ...item,
          slots: item.slots.filter(
            (slot) => slot.start.getTime() !== meetingStartDate.getTime()
          ),
        }))

        dispatch({
          type: SET_AVAILABLE_TIME_SLOTS,
          payload: filteredAvailableTimeSlots,
        })
      } else {
        const updatedAvailableTimeSlots = availableTimeSlots.map((item) => {
          const newItem = { ...item }
          if (isSameDay(newItem.slots[0].start, meetingStartDate)) {
            newItem.slots = [
              ...newItem.slots,
              {
                ...newItem.slots[0],
                start: meetingStartDate,
                end: addMinutes(meetingStartDate, 30),
              },
            ]
            newItem.slots.sort((a, b) => a.start - b.start)

            return newItem
          }
          return item
        })

        dispatch({
          type: SET_AVAILABLE_TIME_SLOTS,
          payload: updatedAvailableTimeSlots,
        })
      }
    }

    dispatch(fetchCalendarEvents(userId))

    dispatch(
      showAlert({
        type: "info",
        message,
      })
    )
  }

export const handleMeetingReservedByPatient =
  (data) => (dispatch, getState) => {
    const {
      user: { userId },
      availableTimeSlots,
    } = getState()

    const { meetingStart } = data

    const meetingStartDate = new Date(meetingStart)

    if (availableTimeSlots) {
      const filteredAvailableTimeSlots = availableTimeSlots.map((item) => ({
        ...item,
        slots: item.slots.filter(
          (slot) => slot.start.getTime() !== meetingStartDate.getTime()
        ),
      }))

      dispatch({
        type: SET_AVAILABLE_TIME_SLOTS,
        payload: filteredAvailableTimeSlots,
      })
    }

    dispatch(fetchCalendarEvents(userId))
  }

export const handleMeetingIgnored = (data) => (dispatch, getState) => {
  const { calendarEvents } = getState()

  if (calendarEvents?.bookings?.length && data?.sessionId) {
    const newBookings = calendarEvents?.bookings.map((booking) =>
      booking.sessionId === data.sessionId
        ? {
            ...booking,
            costReceiver: "ignore",
          }
        : booking
    )
    dispatch({
      type: SET_CALENDAR_EVENTS,
      payload: {
        ...calendarEvents,
        bookings: newBookings,
      },
    })
  }
}
