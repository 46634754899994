import React, { useEffect, useRef } from "react"
// import { useLocalStorage } from "utils/useLocalStorage"
import { connect } from "react-redux"
import {
  Box,
  Typography,
  IconButton,
  Hidden,
  Accordion,
  AccordionSummary,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import AnonymousModeWrapper from "components/AnonymousModeWrapper"
import SessionCountIconWithTooltip from "components/SessionCountIconWithTooltip"
import {
  ArrowBack as ArrowBackIcon,
  // ExpandMore as ExpandMoreIcon,
  // KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material"
import { format } from "date-fns"
import { sv } from "date-fns/locale"
import { getUserAge } from "utils/getUserAge"
import { formatPersonalNumber } from "utils/formatPersonalNumber"
import CopyUserId from "./PatientCopyId"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.lighter_gray,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      borderBottom: 0,
    },
  },
  test: {
    display: "contents",
    "& > div:last-of-type": {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      "& > div:last-of-type": {
        marginBottom: theme.spacing(1),
      },
    },
  },
  question: {
    marginRight: theme.spacing(2),
    "&:last-of-type": {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
  },
  questionText: {
    fontWeight: "bold",
  },
  icon: {
    color: "white",
  },
  accordionTitle: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.white,
    textDecoration: "none",
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1.876, 0),
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  title: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.125rem",
      padding: theme.spacing(1, 0, 0, 0),
    },
  },
  subTitle: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
      lineHeight: "1rem",
    },
  },
  accordion: {
    border: "0rem",
    backgroundColor: theme.palette.primary.main,
  },
  accordionFooter: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
    backgroundColor: theme.palette.gray,
  },
  backButtonWrapper: {
    alignSelf: "center",
    padding: theme.spacing(1.25, 0),
  },
  backButton: {
    color: theme.palette.white,
  },
  arrowUpIcon: {
    cursor: "pointer",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
  },
  sessionEndText: {
    alignSelf: "flex-end",
    padding: theme.spacing(0.5, 0),
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
  text: {
    display: "flex",
    alignItems: "center",
  },
}))

const PatientInformation = ({ activeChat, onBackClick, calendarEvents }) => {
  const classes = useStyles()
  const { userId, userFullName, userSocialSecurity, sessionCount } =
    activeChat || {}
  const topRef = useRef(null)

  const nextVideoMeeting = calendarEvents?.bookings?.find(
    (meeting) =>
      meeting.patientId === userId && new Date(meeting.start) > new Date()
  )

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView()
    }
  }, [activeChat?.chatId])

  if (!userId) {
    return null
  }

  // const toggleInfo = () => {
  //   setCollapsed(!collapsed)
  // }

  const handleBackClick = (e) => {
    e.stopPropagation()

    onBackClick()
  }

  return (
    <Box>
      <Accordion
        variant="outlined"
        className={classes.accordion}
        expanded={false}
        // expanded={!collapsed}
        square
        // onChange={toggleInfo}
      >
        <AccordionSummary
        // expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <Hidden smUp>
            <Box className={classes.backButtonWrapper}>
              <IconButton onClick={handleBackClick}>
                <ArrowBackIcon
                  fontSize="large"
                  className={classes.backButton}
                />
              </IconButton>
            </Box>
          </Hidden>
          <Typography
            component="span"
            variant="body1"
            className={classes.accordionTitle}
          >
            <Box className={classes.titleBox}>
              <Typography
                component="span"
                variant="h4"
                className={classes.title}
              >
                <AnonymousModeWrapper>
                  {userFullName}
                  <span> ({getUserAge(userSocialSecurity)} år)</span>
                </AnonymousModeWrapper>
              </Typography>
              <Typography
                component="span"
                variant="h6"
                className={classes.subTitle}
              >
                <AnonymousModeWrapper>
                  {formatPersonalNumber(userSocialSecurity)}
                  <CopyUserId id={userId} />
                </AnonymousModeWrapper>
              </Typography>
            </Box>
            {activeChat?.chatId && nextVideoMeeting?.start && (
              <Box>
                <AnonymousModeWrapper>
                  <Typography className={`${classes.sessionEndText}`}>
                    Nästa bokade tid:{" "}
                    {`${format(
                      new Date(nextVideoMeeting.start),
                      "d MMM HH:mm",
                      {
                        locale: sv,
                      }
                    ).replace(/\./g, "")}`}
                  </Typography>
                  <Typography className={classes.text}>
                    Antal sessioner: {sessionCount} st
                    {sessionCount > 9 && (
                      <SessionCountIconWithTooltip
                        sessionCount={sessionCount}
                      />
                    )}
                  </Typography>
                </AnonymousModeWrapper>
              </Box>
            )}
          </Typography>
        </AccordionSummary>
        {/* <Box className={classes.accordionFooter} onClick={toggleInfo}>
          <KeyboardArrowUpIcon className={classes.arrowUpIcon} />
        </Box> */}
      </Accordion>
    </Box>
  )
}

const mapStateToProps = ({ chats, calendarEvents }) => {
  const { activeChat } = chats

  return {
    activeChat,
    calendarEvents,
  }
}

export default connect(mapStateToProps)(PatientInformation)
